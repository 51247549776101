@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "markpro";
  src: url('/public/fonts/markpro/MarkPro-ThinItalic.otf');
  font-weight: 100;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-ExtraLight.otf');
  font-weight: 200;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-ExtraLightItalic.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Light.otf');
  font-weight: 300;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro.otf');
  font-weight: 400;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Italic.otf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Book.otf');
  font-weight: 500;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-BookItalic.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Medium.otf');
  font-weight: 600;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-MediumItalic.otf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Bold.otf');
  font-weight: 700;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Heavy.otf');
  font-weight: 800;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-HeavyItalic.otf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-Black.otf');
  font-weight: 900;
}
@font-face {
  font-family: "markpro";
  src: url('/public//fonts/markpro/MarkPro-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}


:root { 
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
*{
  font-family: 'markpro', sans-serif;  
}

.index-color{
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -2;
}

.index-bg{
  background:url(../../public/index-bg.png) no-repeat center center;
  z-index: -3;
  background-size: cover;
  min-height: 100vh !important;
  
}
.about-bg{
  background:url(../../public/tomato.png) no-repeat center center;
  z-index: -3;
  background-size:cover;
}

.circle {
  transform: translate(-50%, -50%);
}

.accent-text{
  background-color: rgba(64, 131, 32, 1);
  padding-bottom: 0rem !important;
  color:white;
  font-size: 1.75rem;
  padding-bottom: 1rem;
  text-align: center;
  position: relative;
  padding-top:.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .5rem !important;
}
.active{
  border-top: 10px solid rgba(64, 131, 32, 1);
  transform: rotate(180deg);
  border-radius: 10px 10px 0 0;
  
}
.inner-div{
  transform: translateY(-50%);
}
@media screen and (min-width: 1024px) {
  .accent-text{
    top: -.35rem;
  padding-top:1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  padding-bottom: 1rem !important;
  
  }
}
#header{
  position: fixed !important;
  top: 0px !important;
  width: 100%;
  z-index: 999;
}
#bg-opacity{
  --tw-bg-opacity: 0.8 !important;
}